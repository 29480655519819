@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';
// ===
// Vendor
// ===
$body-font-family: 'Manrope';
$title-font: 'Comfortaa';
#nprogress .bar {
  background: rgb(0, 132, 255);

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}
#app {
  @import '@design';

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  font-family: manrope, roboto, sans-serif;
}
