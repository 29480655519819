@import '@/scss/variables.scss'
@import '~vuetify/src/styles/styles.sass'

.k-header-date
  right: 0%
  text-align: right
  line-height: normal
  .year
    font-size: 16px

  .mounth
    font-size: 24px
